interface SearchDataProps {
  index: string;
  id?: string;
  target?: string;
  type?: string;
  keywords?: string;
  queryType?: string;
  idCategory?: string[];
  year?: string;
  since?: string;
  fromDataFonte?: string;
  to?: string;
  from?: number;
  size?: number;
  perPage?: number;
  featured?: number;
  idAreaTematica?: string[];
  idMateria?: string[];
  idArgomento?: string[];
  idFonte?: number[];
  tags?: string[];
  orderingType?: string;
  withContent?: boolean;
  withText?: boolean;
  number?: string;
  searchType?: string;
  searchDescription?: string;
  searchInMassime: boolean;
}

export class SearchData {
  private props: SearchDataProps;

  constructor(props: SearchDataProps) {
    this.props = props;
  }

  get properties(): SearchDataProps {
    return this.props;
  }

  // GETTERs and SETTERs
  get index(): string {
    return this.props.index;
  }
  set index(index: string) {
    if (index) {
      this.props.index = index;
    }
  }
  get id(): string | undefined {
    return this.props.id;
  }
  set id(id: string | undefined) {
    this.props.id = id;
  }
  get target(): string | undefined {
    return this.props.target;
  }
  get type(): string | undefined {
    return this.props.type;
  }
  get keywords(): string | undefined {
    return this.props.keywords;
  }
  set keywords(keywords: string | undefined) {
    this.props.keywords = keywords;
  }
  get queryType(): string | undefined {
    return this.props.queryType;
  }
  set queryType(queryType: string | undefined) {
    if (queryType) {
      this.props.queryType = queryType;
    }
  }
  get idCategory(): string[] | undefined {
    return this.props.idCategory;
  }
  set idCategory(idCategory: string[] | undefined) {
    this.props.idCategory = idCategory;
  }
  get idFonte(): number[] | undefined {
    return this.props.idFonte;
  }
  set idFonte(idFonte: number[] | undefined) {
    this.props.idFonte = idFonte;
  }
  get year(): string | undefined {
    return this.props.year;
  }
  set year(year: string | undefined) {
    this.props.year = year;
  }
  get since(): string | undefined {
    return this.props.since;
  }
  set since(since: string | undefined) {
    this.props.since = since;
  }
  get to(): string | undefined {
    return this.props.to;
  }
  set to(to: string | undefined) {
    this.props.to = to;
  }
  get from(): number | undefined {
    return this.props.from;
  }
  set from(from: number | undefined) {
    this.props.from = from;
  }
  get size(): number | undefined {
    return this.props.size;
  }
  set size(size: number | undefined) {
    this.props.size = size;
  }
  get perPage(): number | undefined {
    return this.props.perPage;
  }
  set perPage(perPage: number | undefined) {
    this.props.perPage = perPage;
  }
  get featured(): number | undefined {
    return this.props.featured;
  }
  get idAreaTematica(): string[] | undefined {
    return this.props.idAreaTematica;
  }
  set idAreaTematica(idAreaTematica: string[] | undefined) {
    this.props.idAreaTematica = idAreaTematica;
  }
  get idMateria(): string[] | undefined {
    return this.props.idMateria;
  }
  set idMateria(idMateria: string[] | undefined) {
    this.props.idMateria = idMateria;
  }
  get idArgomento(): string[] | undefined {
    return this.props.idArgomento;
  }
  set idArgomento(idArgomento: string[] | undefined) {
    this.props.idArgomento = idArgomento;
  }

  get number(): string | undefined {
    return this.props.number;
  }
  set number(number: string | undefined) {
    this.props.number = number;
  }

  get tags(): string[] | undefined {
    return this.props.tags;
  }
  set tags(tags: string[] | undefined) {
    if (tags) {
      this.props.tags = tags;
    }
  }

  get fromTime(): string | undefined {
    return this.props.orderingType;
  }
  get orderingType(): string | undefined {
    return this.props.orderingType;
  }
  set orderingType(orderingType: string | undefined) {
    this.props.orderingType = orderingType;
  }
  get withText(): boolean | undefined {
    return this.props.withText;
  }
  set withText(withText: boolean | undefined) {
    this.props.withText = withText;
  }

  get searchType(): string | undefined {
    return this.props.searchType;
  }
  set searchType(searchType: string | undefined) {
    this.props.searchType = searchType;
  }
  get searchDescription(): string | undefined {
    return this.props.searchDescription;
  }
  set searchDescription(searchDescription: string | undefined) {
    this.props.searchDescription = searchDescription;
  }
  get searchInMassime(): boolean {
    return this.props.searchInMassime;
  }
  set searchInMassime(searchInMassime: boolean) {
    this.props.searchInMassime = searchInMassime;
  }
}
