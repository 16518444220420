import moment from 'moment';

export class Utils {
  static async timeout(millis: number) {
    return new Promise((resolve) => setTimeout(resolve, millis));
  }

  static b64DecodeUnicode(text: string): string {
    return decodeURIComponent(
      Array.prototype.map
        .call(atob(text), function(c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );
  }

  static pad(text: string, size: number, padChar: string) {
    let s = text + '';
    while (s.length < size) s = padChar + s;
    return s;
  }

  static sort(prop: any, type: string) {
    return function(a: any, b: any) {
      if (type && type == 'asc') {
        if (a[prop] > b[prop]) {
          return 1;
        } else if (a[prop] < b[prop]) {
          return -1;
        }
      } else if (!type || type == 'desc') {
        if (a[prop] < b[prop]) {
          return 1;
        } else if (a[prop] > b[prop]) {
          return -1;
        }
      }
      return 0;
    };
  }

  static getImage(source: any): string {
    let imageSrc = source.image;
    if (!imageSrc || imageSrc == '') {
      imageSrc =
        process.env.VUE_APP_JURANEWS_BACKOFFICE_URL +
        '/images/placeholder/image-placeholder.jpeg';
    } else {
      imageSrc = process.env.VUE_APP_JURANEWS_BACKOFFICE_URL + '/' + imageSrc;
    }
    return imageSrc;
  }

  static print(divName: string) {
    /* const contentToPrint = document.getElementById(divName);
     if (contentToPrint) {
       const printContents = contentToPrint.innerHTML;
       const originalContents = document.body.innerHTML;
       document.write(printContents)
       document.body.innerHTML = printContents;
       window.print();
       document.body.innerHTML = originalContents;
      // window.location.href = url
       
     }*/
    const disclaimer =
      '©' +
      moment().format('YYYY') +
      ' - Juranet s.r.l - P.I. 05731711007 - Riproduzione Riservata';
    const contentToPrint = document.getElementById(divName);
    let stylesHtml = '';
    for (const node of [
      ...document.querySelectorAll('link[rel="stylesheet"], style'),
    ]) {
      stylesHtml += node.outerHTML;
    }

    if (contentToPrint) {
      const winPrint = window.open(
        '',
        '',
        'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0'
      );

      if (winPrint) {
        winPrint.document.write(`<!DOCTYPE html>
<html>
  <head>
    ${stylesHtml}
  </head>
  <body>
    <img class="center" style="margin-top: 15px;" src="/img/logo.png" height="80" />
    <br/>
    <hr class="solid">
    ${contentToPrint.innerHTML}
    <br/><br/>
    <hr class="solid">
    <div style="text-align: center;">
    ${disclaimer}
    </div>
  </body>
</html>`);
        winPrint.document.close();
        winPrint.focus();
        /* winPrint.print();
        winPrint.close();*/
      }
    }
  }
}
