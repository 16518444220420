interface EventProps {
    id: number;
    name: string;
    start: string;
    end: string;
    idCategory: string;
    color?: string;
    details: string;
}

export class Event {

    private props: EventProps;

    constructor(props: EventProps) {
        this.props = props
    }

    // GETTERs and SETTERs
    get id(): number {
        return this.props.id
    }

    get name(): string {
        return this.props.name
    }

    get start(): string {
        return this.props.start
    }

    get end(): string {
        return this.props.end
    }

    get idCategory(): string {
        return this.props.idCategory
    }

    get details(): string {
        return this.props.details
    }

    get color(): string | undefined {
        return this.props.color
    }

}