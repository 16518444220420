import { Document } from '@/models/document.model';
import { Category } from '@/models/category.model';
import { Service } from '@/models/service.model';
import { Event } from '@/models/event.model';
import { Profile } from '@/models/profile.model';
import { SearchData } from '@/models/search.model';
import { Picklist } from '@/models/picklist.model';
import { Utils } from '@/services/utils';

import axios from 'axios';

class ElasticSearchService {
  constructor() {
    console.log('Creating a new instance of EsService...');
  }

  async countDocs(searchData: SearchData) {
    if (!searchData) {
      return 0;
    }
    const url = process.env.VUE_APP_JURANEWS_ES_SERVICES_URL + '/count';
    const res = await axios.post(url, {
      headers: { apikey: process.env.VUE_APP_API_KEY },
      data: searchData.properties,
    });
    if (res.status === 200) {
      return res.data.body.count;
    }
  }

  async getDoc(searchData: SearchData) {
    const res = await axios.post(
      process.env.VUE_APP_JURANEWS_ES_SERVICES_URL + '/search',
      {
        headers: { apikey: process.env.VUE_APP_API_KEY },
        data: searchData.properties,
      }
    );
    const hit = res.data.body.hits.hits[0];
    if (res.status === 200 && hit) {
      const isJuranews = hit._index == 'juranews';
      const isFragment = searchData.target != undefined;
      const s = res.data.body.hits.hits[0]._source;
      let text = undefined;
      if (s.text) {
        text = Utils.b64DecodeUnicode(s.text);
      }
      return new Document({
        id: s.id,
        seq: 0,
        isFragment: isFragment,
        target: searchData.target ? searchData.target : '',
        type: searchData.type ? searchData.type : '',
        size: 0,
        imageSrc: Utils.getImage(s),
        title: s.title,
        abstract: s.introText,
        materia: s.materia,
        argomento: s.argomento,
        text: text,
        tags: s.tags,
        date: s.dataCreation,
        categories: s.categories,
        idCategory: s.categoryId,
        idAreaTematica: s.idAreaTematica,
        areaTematica: s.areaTematica,
        idMateria: s.idMateria,
        idArgomento: s.idArgomento,
        author: s.autore,
        descrAuthor: s.descrAutore,
        highlights: [],
        index: hit._index,
        access: s.access,
        since: s.since,
        to: s.to,
        isJuranews: isJuranews,
      });
    }
    return undefined;
  }

  async getDocs(searchData: SearchData) {
    const docs: Document[] = [];
    const url = process.env.VUE_APP_JURANEWS_ES_SERVICES_URL + '/search';
    try {
      const res = await axios.post(url, {
        headers: { apikey: process.env.VUE_APP_API_KEY },
        data: searchData.properties,
      });
      let seq = 0;
      const hit = res.data.body.hits.hits[0];
      if (res.status === 200 && hit) {
        for (const val of res.data.body.hits.hits) {
          const isJuranews = val._index == 'juranews';
          const isFragment = searchData.target != undefined;
          const s = val._source;
          const highlights = [];
          if (val.highlight) {
            const hihlight = val.highlight['attachment.content'];
            if (hihlight) {
              for (let i = 0; i < hihlight.length; i++) {
                highlights.push('- ' + hihlight[i]);
              }
            }
          }
          let text = undefined;
          if (s.text) {
            text = Utils.b64DecodeUnicode(s.text);
          }
          docs.push(
            new Document({
              id: s.id,
              seq: seq,
              isFragment: isFragment,
              target: searchData.target ? searchData.target : '',
              type: searchData.type ? searchData.type : '',
              size: 0,
              imageSrc: Utils.getImage(s),
              title: s.title,
              abstract: s.introText,
              materia: s.materia,
              argomento: s.argomento,
              text: text,
              tags: s.tags,
              date: s.dataCreation,
              categories: s.categories,
              idCategory: s.categoryId,
              idAreaTematica: s.idAreaTematica,
              areaTematica: s.areaTematica,
              idMateria: s.idMateria,
              idArgomento: s.idArgomento,
              author: s.autore,
              descrAuthor: s.descrAutore,
              highlights: highlights,
              index: val._index,
              access: s.access,
              since: s.since,
              to: s.to,
              isJuranews: isJuranews,
            })
          );
          seq = seq + 1;
        }
      }
    } catch (err) {
      if (err.message == 'Network Error') {
        throw new Error(
          'Errore di connessione. Verifica di avere una connessione attiva a Internet.'
        );
      }
    }
    return docs;
  }

  async getEvents() {
    const eventsRaw = JSON.parse(await this.getOptions('eventi'));
    const events: Event[] = [];
    for (const e of eventsRaw) {
      events.push(
        new Event({
          id: e.id,
          name: e.title,
          start: e.startDate,
          end: e.endDate,
          idCategory: e.idCategory,
          details: e.details,
          color: 'red',
        })
      );
    }
    return events;
  }

  async getStructure(searchData: SearchData) {
    const res = await axios.post(
      process.env.VUE_APP_JURANEWS_ES_SERVICES_URL + '/structure',
      {
        headers: { apikey: process.env.VUE_APP_API_KEY },
        data: searchData.properties,
      }
    );
    if (res.status === 200) {
      return res.data.body;
    }
  }

  async getServicesInQuickAccessStrip(searchData: SearchData) {
    const services: Service[] = [];
    services.push(
      new Service({
        id: '1',
        seq: 1,
        imageSrc:
          window.location.origin + '/img/home/sintesi-giurisprudenziali.jpg',
        title: 'Sintesi Giurisprudenziali e Schede Autorali',
        description: '',
        routePath: 'Category',
      })
    );
    services.push(
      new Service({
        id: '2',
        seq: 2,
        imageSrc: window.location.origin + '/img/home/richieste-on-demand.jpg',
        title: 'Ricerche on demand',
        description: '',
        routePath: 'Category',
      })
    );
    services.push(
      new Service({
        id: '3',
        seq: 3,
        imageSrc: window.location.origin + '/img/home/biblioteca.jpg',
        title: 'Biblioteca',
        description: '',
        routePath: 'Category',
      })
    );
    services.push(
      new Service({
        id: '4',
        seq: 4,
        imageSrc: window.location.origin + '/img/home/press.jpg',
        title: 'Press - Dicono di noi',
        description: '',
        routePath: 'Category',
      })
    );
    return services;
  }

  async getServicesInServicesStrip(searchData: SearchData) {
    const services: Service[] = [];
    services.push(
      new Service({
        id: '1',
        seq: 1,
        imageSrc:
          'https://images.pexels.com/photos/5668473/pexels-photo-5668473.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260',
        title: 'Titolo servizio',
        description:
          'Etiam congue id nisl ac interdum. Quiscue urna metus, sclererisque...',
        routePath: 'Category',
      })
    );
    services.push(
      new Service({
        id: '2',
        seq: 2,
        imageSrc:
          'https://images.pexels.com/photos/5668473/pexels-photo-5668473.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260',
        title: 'Titolo servizio',
        description:
          'Etiam congue id nisl ac interdum. Quiscue urna metus, sclererisque...',
        routePath: 'Category',
      })
    );
    services.push(
      new Service({
        id: '3',
        seq: 3,
        imageSrc:
          'https://images.pexels.com/photos/5668473/pexels-photo-5668473.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260',
        title: 'Titolo servizio',
        description:
          'Etiam congue id nisl ac interdum. Quiscue urna metus, sclererisque...',
        routePath: 'Category',
      })
    );
    services.push(
      new Service({
        id: '4',
        seq: 4,
        imageSrc:
          'https://images.pexels.com/photos/5668473/pexels-photo-5668473.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260',
        title: 'Titolo servizio',
        description:
          'Etiam congue id nisl ac interdum. Quiscue urna metus, sclererisque...',
        routePath: 'Category',
      })
    );
    services.push(
      new Service({
        id: '5',
        seq: 5,
        imageSrc:
          'https://images.pexels.com/photos/5668473/pexels-photo-5668473.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260',
        title: 'Titolo servizio',
        description:
          'Etiam congue id nisl ac interdum. Quiscue urna metus, sclererisque...',
        routePath: 'Category',
      })
    );
    services.push(
      new Service({
        id: '6',
        seq: 6,
        imageSrc:
          'https://images.pexels.com/photos/5668473/pexels-photo-5668473.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260',
        title: 'Titolo servizio',
        description:
          'Etiam congue id nisl ac interdum. Quiscue urna metus, sclererisque...',
        routePath: 'Category',
      })
    );
    services.push(
      new Service({
        id: '7',
        seq: 7,
        imageSrc:
          'https://images.pexels.com/photos/5668473/pexels-photo-5668473.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260',
        title: 'Titolo servizio',
        description:
          'Etiam congue id nisl ac interdum. Quiscue urna metus, sclererisque...',
        routePath: 'Category',
      })
    );
    services.push(
      new Service({
        id: '8',
        seq: 8,
        imageSrc:
          'https://images.pexels.com/photos/5668473/pexels-photo-5668473.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260',
        title: 'Titolo servizio',
        description:
          'Etiam congue id nisl ac interdum. Quiscue urna metus, sclererisque...',
        routePath: 'Category',
      })
    );

    return services;
  }

  async getProfiles(searchData: SearchData) {
    const profiles: Profile[] = [];
    profiles.push(
      new Profile({
        id: '1',
        seq: 1,
        imageSrc:
          'https://images.pexels.com/photos/5668473/pexels-photo-5668473.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260',
        name: 'Mario Rossi',
        businessRole: 'CEO',
        curriculum:
          'Etiam congue id nisl ac interdum. Quiscue urna metus, sclererisque...',
        routePath: 'Profile',
      })
    );
    profiles.push(
      new Profile({
        id: '2',
        seq: 2,
        imageSrc:
          'https://images.pexels.com/photos/5668473/pexels-photo-5668473.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260',
        name: 'Mario Rossi',
        businessRole: 'CFO',
        curriculum:
          'Etiam congue id nisl ac interdum. Quiscue urna metus, sclererisque...',
        routePath: 'Profile',
      })
    );
    profiles.push(
      new Profile({
        id: '3',
        seq: 3,
        imageSrc:
          'https://images.pexels.com/photos/5668473/pexels-photo-5668473.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260',
        name: 'Mario Rossi',
        businessRole: 'VP',
        curriculum:
          'Etiam congue id nisl ac interdum. Quiscue urna metus, sclererisque...',
        routePath: 'Profile',
      })
    );
    profiles.push(
      new Profile({
        id: '4',
        seq: 4,
        imageSrc:
          'https://images.pexels.com/photos/5668473/pexels-photo-5668473.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260',
        name: 'Mario Rossi',
        businessRole: 'CCO',
        curriculum:
          'Etiam congue id nisl ac interdum. Quiscue urna metus, sclererisque...',
        routePath: 'Profile',
      })
    );
    profiles.push(
      new Profile({
        id: '5',
        seq: 5,
        imageSrc:
          'https://images.pexels.com/photos/5668473/pexels-photo-5668473.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260',
        name: 'Mario Rossi',
        businessRole: 'QC',
        curriculum:
          'Etiam congue id nisl ac interdum. Quiscue urna metus, sclererisque...',
        routePath: 'Profile',
      })
    );
    profiles.push(
      new Profile({
        id: '6',
        seq: 6,
        imageSrc:
          'https://images.pexels.com/photos/5668473/pexels-photo-5668473.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260',
        name: 'Mario Rossi',
        businessRole: 'HR',
        curriculum:
          'Etiam congue id nisl ac interdum. Quiscue urna metus, sclererisque...',
        routePath: 'Profile',
      })
    );
    profiles.push(
      new Profile({
        id: '7',
        seq: 7,
        imageSrc:
          'https://images.pexels.com/photos/5668473/pexels-photo-5668473.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260',
        name: 'Mario Rossi',
        businessRole: 'HR',
        curriculum:
          'Etiam congue id nisl ac interdum. Quiscue urna metus, sclererisque...',
        routePath: 'Profile',
      })
    );
    profiles.push(
      new Profile({
        id: '8',
        seq: 8,
        imageSrc:
          'https://images.pexels.com/photos/5668473/pexels-photo-5668473.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260',
        name: 'Mario Rossi',
        businessRole: 'PR',
        curriculum:
          'Etiam congue id nisl ac interdum. Quiscue urna metus, sclererisque...',
        routePath: 'Profile',
      })
    );

    return profiles;
  }

  async getPicklist(name: string) {
    const picklist: Picklist[] = [];
    const res = await axios.get(
      process.env.VUE_APP_JURANEWS_ES_SERVICES_URL +
      '/options?index=juranews-options&name=' +
      name,
      { headers: { apikey: process.env.VUE_APP_API_KEY } }
    );
    if (res.status === 200) {
      const options = JSON.parse(res.data.body.hits.hits[0]._source.options);
      for (const opt of options) {
        picklist.push(new Picklist({ value: opt.id, label: opt.value }));
      }
    }
    return picklist;
  }

  async getOptions(name: string) {
    const res = await axios.get(
      process.env.VUE_APP_JURANEWS_ES_SERVICES_URL +
      '/options?index=juranews-options&name=' +
      name,
      { headers: { apikey: process.env.VUE_APP_API_KEY } }
    );
    if (res.status === 200) {
      return res.data.body.hits.hits[0]._source.options;
    }
  }
}
export const EsService = new ElasticSearchService();
