import moment from 'moment';

interface DocumentProps {
  id: number;
  seq: number;
  isFragment: boolean;
  imageSrc: string;
  title: string;
  abstract: string;
  text?: string;
  date: string;
  idAreaTematica: string;
  areaTematica: string;
  categories: string[];
  idCategory: string;
  idMateria: string;
  materia: string;
  idArgomento: string;
  argomento: string;
  tags: string[];
  author: string;
  descrAuthor: string;
  target: string;
  type: string;
  size: number;
  highlights: string[];
  index: string;
  access: number;
  since: string;
  to: string;
  isJuranews: boolean;
}

export class Document {
  private props: DocumentProps;

  constructor(props: DocumentProps) {
    this.props = props;
  }

  // GETTERs and SETTERs
  get id(): number {
    return this.props.id;
  }
  get isFragment(): boolean {
    return this.props.isFragment;
  }
  get seq(): number {
    return this.props.seq;
  }
  set seq(seq: number) {
    this.props.seq = seq;
  }
  get imageSrc(): string {
    return this.props.imageSrc;
  }

  get materia(): string {
    return this.props.materia;
  }

  get argomento(): string {
    return this.props.argomento;
  }

  get title(): string {
    return this.props.title;
  }

  get shortTitle1(): string {
    let shortTitle = this.props.title;
    if (shortTitle.length > 170) {
      shortTitle = shortTitle.substr(0, 170) + ' ... ';
    }
    return shortTitle;
  }

  get abstract(): string {
    return this.props.abstract;
  }

  get shortAbstract1(): string {
    let shortAbstract = this.props.abstract;
    if (shortAbstract.length > 600) {
      shortAbstract = shortAbstract.substr(0, 600) + ' ... ';
    }
    return shortAbstract;
  }

  get shortAbstract2(): string {
    let shortAbstract = this.props.abstract;
    if (shortAbstract.length > 400) {
      shortAbstract = shortAbstract.substr(0, 400) + ' ... ';
    }
    return shortAbstract;
  }

  get shortText1(): string {
    const maxChars = 400;
    let shortText = this.props.text;
    if (!shortText) {
      return '';
    }
    if (shortText && shortText.length > maxChars) {
      shortText = shortText.substr(0, maxChars);
    }
    const ia1 = shortText!.lastIndexOf('<a');
    const ia2 = shortText!.lastIndexOf('</a>');
    if (ia2 < ia1) {
      const remainAbstract = this.props.text!.substr(
        301,
        this.props.text!.length
      );
      const ia3 = remainAbstract.indexOf('</a>');
      shortText = this.props.text!.substr(0, maxChars + ia3 + 5);
    }
    return shortText + ' ... ';
  }

  get text(): string | undefined {
    return this.props.text;
  }

  get date(): string {
    return this.props.date;
  }

  get dateFormatted(): string | undefined {
    if (this.props.date) {
      return moment(this.props.date, 'YYYY-MM-DD')
        .locale('it')
        .format('DD MMMM YYYY');
    }
    return undefined;
  }

  get dateFormattedExtended(): string {
    const dateFormattedExtended = moment(this.props.date, 'YYYY-MM-DD')
      .locale('it')
      .format('dddd, DD MMMM YYYY');
    return (
      dateFormattedExtended.charAt(0).toUpperCase() +
      dateFormattedExtended.slice(1)
    );
  }

  get categories(): string[] {
    return this.props.categories;
  }

  get idCategory(): string {
    return this.props.idCategory;
  }

  get idMateria(): string {
    return this.props.idMateria;
  }

  get idArgomento(): string {
    return this.props.idArgomento;
  }

  get idAreaTematica(): string {
    return this.props.idAreaTematica;
  }

  get author(): string {
    return this.props.author;
  }

  get descrAuthor(): string {
    return this.props.descrAuthor;
  }

  get tags(): string[] {
    return this.props.tags;
  }

  get target(): string {
    return this.props.target;
  }

  get type(): string {
    return this.props.type;
  }
  get access(): number {
    return this.props.access;
  }
  get size(): number {
    return this.props.size;
  }
  get highlights(): string[] {
    return this.props.highlights;
  }
  get index(): string {
    return this.props.index;
  }
  get url(): string {
    return window.location.href;
  }
  get since(): string {
    return this.props.since;
  }
  get to(): string {
    return this.props.to;
  }

  get isJuranews(): boolean {
    return this.props.isJuranews;
  }
}
